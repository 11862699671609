@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.OnlineDetector {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;

    @include breakpoint(md) {
      width: 100% !important;
    }

    &:before {
      display: none;
    }
  }
}