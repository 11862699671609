@import '../../base/breakpoints';
@import '../../base/mixins';
.acceptDigitalLicence {
  @include element-width(100);
  @include element-list;

  .checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    align-items: center;
    justify-content: flex-start;

    label {
      @include fontweight(vlight);
      margin-bottom: 0;
    }

    input {
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
      padding: 10px;
    }
  }
}
