@import './colors';
@import './typography';
@import './breakpoints';

@mixin border-bottom() {
  border-bottom: 1px solid $alto;
}

@mixin form-element() {
  @include fontfamily(noto);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.7rem;
  border: 1px solid $silver-sand;
  background-color: $wild-sand;
  color: $mine-shaft;
  border-radius: 4px;
  @include fontsize(sm);

  &::placeholder {
    color: $dove-gray !important;
    font-weight: 200 !important;
  }
}

@mixin button {
  @include fontfamily(poppins);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 0;
  border-width: 2px;
  border-color: $yellow-sea;
  border-style: solid;
  text-align: center;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  text-decoration: none;
  @include fontweight(medium);
  width: 100%;

  @include breakpoint(xs) {
    width: auto;
  }

  cursor: pointer;
  color: $black;
  background: $yellow-sea;

  &:focus,
  &:hover {
    background: $sunglow;
    border-color: $sunglow;
  }
  &.reverse {
    background: $white;

    &:focus,
    &:hover {
      background: $varden;
    }
  }
}

@mixin home-width {
  @include breakpoint(sm) {
    width: 44rem;
  }

  @include breakpoint(md) {
    width: 50rem;
  }

  @include breakpoint(lg) {
    width: 69.375rem;
  }
}
@mixin content-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include home-width;
  background: $white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 1.6rem 1.2rem;
  margin: 0 auto;

  @include breakpoint(sm) {
    padding: 1.2rem;
  }

  @include breakpoint(md) {
    padding: 1.4rem;
  }

  @include breakpoint(lg) {
    padding: 2.4rem 1.6rem;
  }
  section {
    margin-top: 2.4rem;
  }
  section:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
}
@mixin content-block {
  padding: 5rem 1rem 5rem 1rem;

  @include breakpoint(sm) {
    padding: 5rem 1rem 5rem 1rem;
    width: 24rem;
  }
}
@mixin inner-content-block {
  padding: 1.2rem;

  @include breakpoint(sm) {
    padding: 2rem 2rem;
  }
}
@mixin full-width-container {
  @include inner-content-block;

  .inner {
    @include home-width;
    @include fontsize(xs);
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
      width: 48rem;
    }

    @include breakpoint(md) {
      width: 48rem;
    }

    @include breakpoint(lg) {
      width: 59.375rem;
    }
  }
}
@mixin step-process-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .box {
    z-index: 1;

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(sm) {
      grid-column: span 1 / span 1;
      align-items: center;
      text-align: center;
    }
    .head {
      display: flex;
      flex-direction: row;
      gap: 1.2rem;
      @include breakpoint(sm) {
        flex-direction: column;
        align-items: center;
      }
      .index {
        @include fontsize(md);
        @include fontfamily(poppins);
        border-radius: 100%;
        border: 3px solid $yellow-sea;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        place-content: center;
        align-items: center;
        @include fontweight(bold);
      }
      .title {
        @include fontfamily(poppins);
        @include fontweight(bold);
        margin: 0.8em 0;
        font-size: 1.2rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      gap: 1.2rem;

      .vpath-container {
        min-width: 50px;
        display: flex;
        justify-content: center;
        flex-grow: 1;

        .vpath {
          height: 100%;
          width: 3px;
          background: $black;
        }
        @include breakpoint(xs) {
          display: none;
        }
      }
      p {
        margin-bottom: 0;

        @include breakpoint(xs) {
          margin-bottom: auto;
        }
      }
    }
  }
  .path {
    display: none;

    @include breakpoint(xs) {
      display: inherit;
    }
  }
  .path1 {
    z-index: 0;
    position: absolute;
    width: 24%;
    top: 26px;
    left: 21%;
    border-bottom: 3px solid $black;
  }
  .path2 {
    z-index: 0;
    position: absolute;
    width: 24%;
    top: 26px;
    right: 21%;
    border-bottom: 3px solid $black;
  }
}
@mixin site-notice {
  @include fontsize(xs);
  padding: 1.2rem 1rem;
  z-index: 99;

  @include breakpoint(sm) {
    padding: 1.4rem 5rem;
    width: calc(100% - 10rem);
  }
  @media print {
    display: none;
  }

  .inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
    @include breakpoint(sm) {
      align-items: center;
    }
    p {
      margin-bottom: 0;
    }
  }
  .icon {
    .svg-inline--fa {
      @include fontsize(md);
      margin-right: 0;
    }
  }
}
@mixin element-list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
@mixin form-element-container-flex {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
@mixin element-width($width) {
  @if $width == 100 {
    width: auto;
  } @else if $width == 75 {
    @include breakpoint(sm) {
      width: 75%;
    }
  } @else if $width == 50 {
    @include breakpoint(sm) {
      width: 50%;
    }
  } @else if $width == 25 {
    @include breakpoint(sm) {
      width: 25%;
    }
  } @else if $width == 33 {
    @include breakpoint(sm) {
      width: 33%;
    }
  } @else if $width == 66 {
    @include breakpoint(sm) {
      width: 66%;
    }
  } @else {
    @warn "element-width mixin supports: 100, 50, 25, 75, 33, 66";
  }
}
@mixin button-set {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  @include breakpoint(sm) {
    flex-direction: row;
  }
}
@mixin photo-uploads {
  @include element-width(100);
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info {
    @include inner-content-block;
    background: $varden;
    @include element-width(100);
    grid-column: 1 / span 2;
    display: none;
    @include breakpoint(sm) {
      grid-column: 1 / span 3;
    }
  }
  div.image-crop.has-image ~ .info {
    display: flex;
  }
  .spacer {
    display: none;
    @include breakpoint(sm) {
      display: block;
    }
  }
}
@mixin image-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  @include breakpoint(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .image-container {
    grid-column: span 1 / span 1;
    clear: both;
    float: none;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    .description {
      width: 100%;
      text-align: center;
      @include fontsize(xs);
    }
    .svg-inline--fa {
      margin: 0;
    }
    .icon-container {
      display: none;
    }
  }
}
