@mixin fontsize($class) {
  @if $class == xxs {
    font-size: 0.75rem;
    line-height: 0.875rem;
  } @else if $class == xs {
    font-size: 0.875rem;
    line-height: 1rem;
  } @else if $class == sm {
    font-size: 1rem;
    line-height: 1.4rem;
  } @else if $class == md {
    font-size: 1.125rem;
    line-height: 1.375rem;
  } @else if $class == lg {
    font-size: 1.5rem;
    line-height: 1.75rem;
  } @else if $class == xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  } @else if $class == xxl {
    font-size: 2.5rem;
    line-height: 3rem;
  } @else if $class == xxxl {
    font-size: 3.75rem;
    line-height: 4.5rem;
  } @else if $class == xxxxl {
    font-size: 8rem;
    line-height: 8.5rem;
  } @else {
    @warn "Fontgrid mixin supports: xxs, xs, sm, md, lg, xl, xxl, xxxl, xxxxl";
  }
}

@mixin fontfamily($class) {
  @if $class == noto {
    font-family: 'Noto Sans', sans-serif;
  } @else if $class == poppins {
    font-family: 'Poppins', sans-serif;
  } @else {
    @warn "Fontfamily mixin supports: noto, poppins";
  }
}

@mixin fontweight($class) {
  @if $class == vlight {
    font-weight: 300;
  } @else if $class == light {
    font-weight: 400;
  } @else if $class == medium {
    font-weight: 500;
  } @else if $class == bold {
    font-weight: 600;
  } @else if $class == vbold {
    font-weight: 700;
  } @else {
    @warn "Fontweight mixin supports: vlight, light, medium, bold, vbold";
  }
}
